import Form from "react-bootstrap/Form";
import Field from "../Field";
import { FieldValues, UseFormRegister } from "react-hook-form";
import type { CoreFieldProps } from "../types";

type CheckboxProps = {
  type: "checkbox";
  value?: string;
};

type RadioProps = {
  type: "radio";
  value: string;
};

type InputProps<T extends FieldValues> = CoreFieldProps<T> & (CheckboxProps | RadioProps) & {
  register: UseFormRegister<T>;
};

export default function RadioCheckboxField<T extends FieldValues>(props: InputProps<T>) {
  const {
    name,
    type = "checkbox",
    value,
    label,
    required = true,
    disabled = false,
    isSubmitted = false,
    isValid,
    isInvalid,
    register,
    getFieldState,
  } = props;
  
  return (
    <Field {...props} hideLabel={true}>
      <Form.Check {...(type === "radio" && { id: value } )}>
        <Form.Check.Input
          type={type}
          {...(type === "radio" && { value: value } )}
          {...register(name)}
          isValid={isValid !== undefined ? isValid : isSubmitted === true && getFieldState(name)?.invalid === false}
          isInvalid={isInvalid !== undefined ? isInvalid : isSubmitted === true && getFieldState(name)?.invalid === true}
          aria-describedby={`${name}HelpText`} // This links the help text to the input field for screen readers
          disabled={disabled}
        />
        <Form.Check.Label {...(type === "radio" && { htmlFor: value } )}>
          {label} {required !== true && <span className="text-muted">(optional)</span>}
        </Form.Check.Label>
      </Form.Check>
    </Field>
  );
}